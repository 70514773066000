import React, { useState, useEffect, useMemo } from 'react'; 
import { UserOutlined, QrcodeOutlined, ShopOutlined, DollarOutlined, LeftOutlined, RightOutlined, ReloadOutlined, MoreOutlined, CloseOutlined } from '@ant-design/icons';
import { Card, Col, Row, Button, Skeleton, Select, Segmented, DatePicker, Table, Space, Drawer } from 'antd';
import { useSelector } from 'react-redux';
import { setLoading } from '../../../redux/loadingSlicer';
import { RootState } from '../../../redux/appState';
import { makeRequest } from '../../helpers/axios-manipulator';
import KpiCard from '../../components/KpiCard/KpiCard';
import { Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area,
         ComposedChart, Bar, BarChart, LabelList
        } from 'recharts';
import { createStyles } from 'antd-style';

import type { ColumnsType } from "antd/es/table";

const COLOR_1 = '#82ca9d';
const COLOR_2 = '#3b82f6';
const COLOR_3 = '#8884d8';
const COLOR_4 = '#ff7300';

const { RangePicker } = DatePicker;

const useStyle = createStyles(({ css, token }) => {
  const { antCls } = token;
  return {
    customTable: css`
      ${antCls}-table {
        ${antCls}-table-container {
          ${antCls}-table-body,
          ${antCls}-table-content {
            scrollbar-width: thin;
            scrollbar-color: #eaeaea transparent;
            scrollbar-gutter: stable;
          }
        }
      }
    `,
  };
});

const PAGES = [
  {
    id: 'overview',
    label: 'Overview',
    value: 0,
  },
  {
    id: 'orders',
    label: 'Orders',
    value: 1,
  },
  {
    id: 'artists',
    label: 'Artists',
    value: 2,
  },
];


const PLACARDS_BY = {
  ARTIST: { default: "Artist", lowerCase: "artist", upperCase: "ARTIST"},
  ARTWORK: { default: "Artwork", lowerCase: "artwork", upperCase: "ARTWORK"},
};

const useFilterByDateRange = (data, range, dates) => {
  return useMemo(() => {
    const now = new Date();
    return data.filter(({ created_date }) => {
      const date = new Date(created_date);
      switch (range) {
        case DATE_RANGE.TODAY.label:
          return date.toDateString() === now.toDateString();
        case DATE_RANGE.WEEK.label:
          const weekAgo = new Date();
          weekAgo.setDate(now.getDate() - 7);
          return date >= weekAgo;
        case DATE_RANGE.MONTH.label:
          return date.getMonth() === now.getMonth() && date.getFullYear() === now.getFullYear();
        case DATE_RANGE.YEAR.label:
          return date.getFullYear() === now.getFullYear();
        case DATE_RANGE.CUSTOM.label:
          if (dates && dates?.length === 2) {
            return date >= dates[0].toDate() && date <= dates[1].toDate();
          } else {
            return false;
          }
        default:
          return true; // No filter (all time)
      }
    });
  }, [data, range, dates]); // Recomputes only when `data` or `range` changes
}

const DATE_RANGE = { 
  ALL: { id: "all", label: "All Time"},
  YEAR: {id: "year", label: "Year"},
  MONTH: {id: "month", label: "Month"},
  WEEK: {id: "week", label: "Week"},
  TODAY: {id: "today", label: "Today"},
  CUSTOM: {id: "custom", label: "Custom"},
};

const INVENTORY_SALES_CHART_TYPE = {
  SALES: { id: "sales", label: "Sales"},
  INVENTORY: { id: "inventory", label: "Inventory" },
  BOTH: { id: "both", label: "Both"},
};


const Home = () => {
  const [data, setData] = useState<any>({});
  const user = useSelector((state: RootState) => state.user.user);
  const {styles} = useStyle();
  const [tableType, setTableType] = useState('sales');
  const [currentPage, setCurrentPage] = useState(0);
  const [showPlacardsDrawer, setShowPlacardsDrawer] = useState(false);
  const [placardsBy, setPlacardsBy] = useState(PLACARDS_BY.ARTWORK);
  const [selectedRange, setSelectedRange] = useState(DATE_RANGE.ALL.label);
  const [customDates, setCustomDates] = useState(null);
  const filteredDataByDateRange = useFilterByDateRange(data?.placardsScanned?.rows || [], selectedRange || null, customDates);
  const [inventorySalesChartType, setInventorySalesChartType] = useState(INVENTORY_SALES_CHART_TYPE.SALES.label);
  
  const handleSegmentChange = (value) => {
    setTableType(value); // Update state based on the selected segment
  };

  const fetchKpiData = async () => {
    console.log("Fetching KPI data...")
    try {
      const response = await makeRequest({
        origin: window.location.origin,
        method: 'get',
        url: '/kpiData',
        headers: {},
        data: {
          rootUser: isRootUser(),
          userId: user.id,
        },
      });
      setData(response)
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchKpiData();
    
  }, []);

  const isRootUser = () => {
    return ( user && user?.id === '0' && user?.profile_name === 'Root');
  }

  /* TBD>> Do we need to fetch artworks here???
  const getArtworks = async (userId: string) => {
    try {
      const response = await makeRequest({
        origin: window.location.origin,
        method: 'get',
        url: `/api-artwork-user/${userId}`,
        headers: {},
      });
      setArtworks(response)
      dispatch(setLoading(false));
    } catch (error) {
      console.error('Error fetching Artworks for placards:', error);
    }
  };

  const getArtworksForCurrentUser = async ( ) => {
    dispatch(setLoading(true));
    try {
      const response = await makeRequest({
        origin: window.location.origin,
        method: 'get',
        url: `/getArtworksByRepId/${user.id}`,
        headers: {},
      });
      setArtworks(response);
    } catch (error) {
      console.error('Error fetching Artworks for placards:', error);
      message.error('Error fetching Artworks for placards', 5);
    }
    dispatch(setLoading(false));
  }*/

  // Get unique values for filters
  const uniqueArtists = [...new Set(data?.orders?.rows?.map((item) => item.artist_username))];

  // Get unique collectors
  const uniqueCollectors = [...new Set(data?.orders?.rows?.map((item) => item.owner_full_name))];

  const placardsByArtworkColumns: ColumnsType<any> = [
    {
      title: 'Title',
      dataIndex: 'title',
      width: 150,
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: 'Placard',
      dataIndex: 'placard_code',
      width: 150,
      sorter: (a, b) => a.placard_code.localeCompare(b.placard_code),
    },
    {
      title: 'Scans',
      dataIndex: 'count',
      width: 150,
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.count - b.count,
    },
  ];

  const placardsByArtistColumns: ColumnsType<any> = [
    {
      title: 'Artist',
      dataIndex: 'display_name',
      width: 150,
      sorter: (a, b) => a.display_name.localeCompare(b.display_name),
    },
    {
      title: 'Scans',
      dataIndex: 'count',
      width: 150,
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.count - b.count,
    },
  ];

  const ordersColumns: ColumnsType<any> = [
    {
      title: 'Order ID',
      dataIndex: 'id',
      width: 150,
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Artist Payout',
      dataIndex: 'artist_payout',
      width: 150,
      render: (amount) => (
        <span>${Number(amount).toFixed(2)}</span>
      ),
      sorter: (a, b) => a.artist_payout - b.artist_payout,
    },
    {
      title: 'Artist',
      dataIndex: 'artist_username',
      filters: uniqueArtists.map((artist) => ({
        text: artist,
        value: artist,
      })),
      onFilter: (value, record) => record.artist_username === value,
    },
    {
      title: 'Order Date',
      dataIndex: 'order_date',
      defaultSortOrder: "descend",
      sorter: (a, b) => new Date(a.order_date).getTime() - new Date(b.order_date).getTime(), 
      render: (text) => {
        if (!text) return "N/A"; // Handle null or undefined values
      
        const date = new Date(text);
        return `${date.toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
          timeZone: "America/New_York", // Set timezone to EST
        })} ${date.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
          timeZone: "America/New_York", // Set timezone to EST
        })}`;
      },
    },
    {
      title: 'Artwork',
      dataIndex: 'artwork_url',
      render: (artwork_url) => (
        <span style={{maxWidth: "120px", maxHeight: "20px"}}><img src = {artwork_url} height={40} alt="Artwork"/></span>
      ),
      width: 100
    },
    {
      title: 'Collector',
      dataIndex: 'owner_full_name',
      filters: uniqueCollectors.map((collector) => ({
        text: collector,
        value: collector,
      })),
      onFilter: (value, record) => record.owner_full_name === value,
    },
    {
      title: 'Collector Email',
      dataIndex: 'owner_email',
    },
  ];

  const revenueColumnsRoot: ColumnsType<any> = [
    {
      title: 'Platform Fee',
      dataIndex: 'fees',
      sorter: (a, b) => b - a,
      render: (val) => formatNumberToReadable(val),
    },
    {
      title: 'Business Premium',
      dataIndex: 'ujamaa_rep_fees',
      render: (val) => {
        if (!val) return "0"; // Handle empty or undefined values gracefully
    
        const total = val
          .split(";") // Split the string into an array
          .map(Number) // Convert each value to a number
          .filter(num => !isNaN(num)) // Remove any invalid numbers
          .reduce((sum, num) => sum + num, 0); // Sum up the values
        return formatNumberToReadable(total); 
      }
    },
    {
      title: 'Sales Price',
      dataIndex: 'artwork_price',
      render: (val) => formatNumberToReadable(val),
      sorter: (a, b) => b.artwork_price - a.artwork_price,
    },
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: 'Artist',
      dataIndex: 'artist_username',
    },
    {
      title: 'Buyer',
      dataIndex: 'owner_full_name',
    },
  ];

  const revenueColumnsNonRoot: ColumnsType<any> = [
    {
      title: 'Payout',
      dataIndex: 'rep_payout',
      render: (_, record) => {
        if (!record.rep_id || !record.rep_payout) return null; // Handle missing values
        const repIds = record.rep_id.split(";");
        const repPayouts = record.rep_payout.split(";");
        const index = repIds.findIndex((id) => id === user.id);
        return index !== -1 ? formatNumberToReadable(Number(repPayouts[index])) || 0 : null;
      },
    },
    {
      title: 'Sales Price',
      dataIndex: 'artwork_price',
      render: (val) => formatNumberToReadable(val),
    },
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: 'Artist',
      dataIndex: 'artist_username',
    },
    {
      title: 'Buyer',
      dataIndex: 'owner_full_name',
    },
    {
      title: 'Buyer Email',
      dataIndex: 'owner_email',
    },
  ];

  const allArtistsColumns: ColumnsType<any> = [
    {
      title: 'Name',
      dataIndex: 'display_name',
      width: 350,
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.display_name.localeCompare(b.display_name),
    },
    {
      title: 'Location',
      dataIndex: 'location',
      width: 150,
    },
    {
      title: 'Profile',
      dataIndex: 'profile_name',
      render: (_, record) => {
        return (
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {record.profile_image_url && (
              <img
                src={record.profile_image_url}
                alt="Profile"
                referrerPolicy="no-referrer"
                style={{ width: 40, height: 40, borderRadius: "50%" }}
              />
            )}
            <span>{record.profile_name}</span>
          </div>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
  ];

  const repArtistsColumns: ColumnsType<any> = [
    {
      title: 'Name',
      dataIndex: 'display_name',
      width: 350,
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.display_name.localeCompare(b.display_name),
    },
    {
      title: 'Fee',
      dataIndex: 'fee',
      render: (fee) => `${fee}%`,
      sorter: (a, b) => a.fee - b.fee,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text) => {
        // Check if the status is 'active'
        return text === 'active' ? (
          <span style={{ color: 'green', textTransform: 'uppercase' }}>ACTIVE</span>
        ) : (
          text
        );
      },
    },
    {
      title: 'Location',
      dataIndex: 'location',
      width: 150,
    },
    {
      title: 'Profile',
      dataIndex: 'profile_name',
      render: (_, record) => {
        return (
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {record.profile_image_url && (
              <img
                src={record.profile_image_url}
                alt="Profile"
                referrerPolicy="no-referrer"
                style={{ width: 40, height: 40, borderRadius: "50%" }}
              />
            )}
            <span>{record.profile_name}</span>
          </div>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
  ];
  
  const inventoryColumns = [
    {
      title: 'Created On',
      dataIndex: 'created_date',
      width: 150,
      defaultSortOrder: "descend",
      sorter: (a, b) => new Date(a.created_date).getTime() - new Date(b.created_date).getTime(), 
      render: (text) => {
        if (!text) return "N/A"; // Handle null or undefined values
      
        const date = new Date(text);
        return `${date.toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
          timeZone: "America/New_York", // Set timezone to EST
        })} ${date.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
          timeZone: "America/New_York", // Set timezone to EST
        })}`;
      },
    },
    {
      title: 'Artwork',
      dataIndex: 'image_url',
      render: (image_url) => (
        <span style={{maxWidth: "120px", maxHeight: "20px"}}><img src = {image_url}  alt="Artwork" height={40}/></span>
      ),
      width: 80
    },
    {
      title: 'Title',
      dataIndex: 'title',
      width: 150,
    },
    {
      title: 'Artist',
      dataIndex: 'display_name',
      width: 150,
    },
    {
      title: 'Price',
      dataIndex: 'final_price',
      width: 100,
      render: (val) => formatNumberToReadable(val),
      sorter: (a, b) => b.final_price - a.final_price,
    },
  ];

  const inventoryColumnsNonRoot = [
    {
      title: 'Created On',
      dataIndex: 'created_date',
      width: 150,
      defaultSortOrder: "descend",
      sorter: (a, b) => new Date(a.created_date).getTime() - new Date(b.created_date).getTime(), 
      render: (text) => {
        if (!text) return "N/A"; // Handle null or undefined values
      
        const date = new Date(text);
        return `${date.toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
          timeZone: "America/New_York", // Set timezone to EST
        })} ${date.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
          timeZone: "America/New_York", // Set timezone to EST
        })}`;
      },
    },
    {
      title: 'Artwork',
      dataIndex: 'image_url',
      render: (image_url) => (
        <span style={{maxWidth: "120px", maxHeight: "20px"}}><img src = {image_url}  alt="Artwork" height={40}/></span>
      ),
      width: 80
    },
    {
      title: 'Title',
      dataIndex: 'title',
      width: 150,
    },
    {
      title: 'Artist',
      dataIndex: 'display_name',
      width: 150,
    },
    {
      title: 'Price',
      dataIndex: 'final_price',
      width: 100,
      render: (val) => formatNumberToReadable(val),
      sorter: (a, b) => a.final_price.localeCompare(b.final_price),
    },
    /* TBD>> {
      title: 'Commission',
      dataIndex: 'royalty',
      width: 100,
      render: (val) => formatNumberToReadable(val)
    },*/
  ];

  const placardsColumnsRoot = [
    {
      title: 'Timestamp',
      dataIndex: 'created_date',
      defaultSortOrder: "descend",
      sorter: (a, b) => new Date(a.created_date).getTime() - new Date(b.created_date).getTime(), 
      render: (text) => {
        if (!text) return "N/A"; // Handle null or undefined values
      
        const date = new Date(text);
        return `${date.toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
          timeZone: "America/New_York", // Set timezone to EST
        })} ${date.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
          timeZone: "America/New_York", // Set timezone to EST
        })}`;
      },
    },
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: 'Artist',
      dataIndex: 'display_name',
    },
    {
      title: 'Rep',
      dataIndex: 'rep_username',
    },
    {
      title: 'Placard',
      dataIndex: 'placard_code',
      sorter: (a, b) => a.placard_code.localeCompare(b.placard_code),
    },
    {
      title: 'Price',
      dataIndex: 'final_price',
      render: (val) => formatNumberToReadable(val),
    },
  ];

  const placardsColumnsNonRoot = [
    {
      title: 'Timestamp',
      dataIndex: 'created_date',
      defaultSortOrder: "descend",
      sorter: (a, b) => new Date(a.created_date).getTime() - new Date(b.created_date).getTime(),
      render: (text) => {
        if (!text) return "N/A"; // Handle null or undefined values
      
        const date = new Date(text);
        return `${date.toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
          timeZone: "America/New_York", // Set timezone to EST
        })} ${date.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
          timeZone: "America/New_York", // Set timezone to EST
        })}`;
      },
    },
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: 'Artist',
      dataIndex: 'display_name',
    },
    {
      title: 'Placard',
      dataIndex: 'placard_code',
      sorter: (a, b) => a.placard_code.localeCompare(b.placard_code),
    },
    {
      title: 'Price',
      dataIndex: 'final_price',
      render: (val) => formatNumberToReadable(val),
    },
  ];

  const getInventoryTotal = (data) => {
    let total;
    if( isRootUser() ) {
      total = data?.inventory?.rows?.reduce((acc, item) => {
        if (item.type === 1) {
          // Count items of type 1 (originals)
          acc += 1;
        } else if (item.type === 2) {
          // Add amount_available for items of type 2 (prints)
          acc += item.amount_available;
        }
        return acc;
      }, 0);
    } else {
      total = data?.dealerOriginals?.rows?.length + data?.dealerPrints?.rows?.length;
    }

    return total || "0";
  }

  const getInventoryTotalValue = (data) => {
    let totalValue = 0;

    if( isRootUser() ) {
      totalValue = data?.inventory?.rows?.slice()?.reduce((acc, item) => {
        if (item.type === 1) {
          // Count items of type 1 (originals)
          acc += Number(item.final_price);
        } else if (item.type === 2) {
          // Add amount_available for items of type 2 (prints)
          acc += Number(item.amount_available * item.final_price);
        }
        return acc;
      }, 0);
    } else {
      const originalsValue = data?.dealerOriginals?.rows?.slice()?.reduce((acc, item) => {
        acc += Number(item.final_price);
        return acc;
      }, 0);

      const printsValue = data?.dealerPrints?.rows?.slice()?.reduce((acc, item) => {
        acc += Number(item.amount_available * item.final_price);
        return acc;
      }, 0);
      totalValue = originalsValue + printsValue;
    }

    return formatNumberToReadable(totalValue);
  }

  const getInventoryOriginals = (data) => {
    if( isRootUser() ) {
      return data?.inventory?.rows?.filter( item => item.type === 1 )?.length || 0; 
    }
    return data?.dealerOriginals?.rows?.length;
  }

  const getInventoryOriginalsValue = (data) => {
    let originalsValue = 0;
    if( isRootUser() ) {
      originalsValue = data?.inventory?.rows?.slice()?.reduce((acc, item) => {
        if (item.type === 1) {
          // Count items of type 1 (originals)
          acc += Number(item.final_price);
        } 
        return acc;
      }, 0);
    } else {
      originalsValue = data?.dealerOriginals?.rows?.slice()?.reduce((acc, item) => {
        acc += Number(item.final_price);
        return acc;
      }, 0);
    }

    return formatNumberToReadable(originalsValue); 
  }

  const getInventoryPrints = (data) => {
    if( isRootUser() ) {
      const prints = data?.inventory?.rows?.reduce((acc, item) => {
        if (item.type === 1) {
          // Do nothing
        } else if (item.type === 2) {
          // Add amount_available for items of type 2 (prints)
          acc += item.amount_available;
        }
        return acc;
      }, 0);
      return prints;
    } 
    
    return data?.dealerPrints?.rows?.length;
  }

  const getInventoryPrintsValue = (data) => {
    let printsValue = 0;
    if( isRootUser() ) {
      printsValue = data?.inventory?.rows?.slice()?.reduce((acc, item) => {
        if (item.type === 1) {
          // Do nothing
        } else if (item.type === 2) {
          // Add amount_available for items of type 2 (prints)
          acc += Number(item.final_price * item.amount_available);
        }
        return acc;
      }, 0);
    } else {
      printsValue = data?.dealerPrints?.rows?.slice()?.reduce((acc, item) => {
        // Add amount_available for items of type 2 (prints)
        acc += Number(item.final_price * item.amount_available);
        return acc;
      }, 0);
    }

    return formatNumberToReadable(printsValue);
  }

  const formatNumberToReadable = (num) => {
    if (num === null || num === undefined || isNaN(num)) {
      return "N/A"; // Handle invalid inputs gracefully
    }

    const number = Number(num);
    const absNumber = Math.abs(number);

    if (absNumber >= 1e9) {
      // Billions
      return `$${(number / 1e9).toFixed(1).replace(/\.00$/, "")}B`;
    } else if (absNumber >= 1e6) {
      // Millions
      return `$${(number / 1e6).toFixed(1).replace(/\.00$/, "")}M`;
    } else if (absNumber >= 1e3) {
      // Thousands
      return `$${(Math.floor(number / 100) / 10).toFixed(2).replace(/\.00$/, "")}K`;
    } else {
      // Less than a thousand
      return '$' + number.toFixed(1).toString();
    }
  }

  const getUjamaaRevenue = (data) => {
    const bizRevenue = data.reduce((acc, item) => {
      if (typeof item.ujamaa_rep_fees === "string") {
        acc += item.ujamaa_rep_fees
          .split(";") // Split into an array of values
          .map(Number) // Convert each to a number
          .filter((num) => !isNaN(num)) // Remove invalid numbers
          .reduce((sum, num) => sum + num, 0); // Sum the valid numbers
      }
      return acc;
    }, 0);

    const artistRevenue = data.reduce((acc, item) => {
      if( !isNaN( Number(item.ujamaa_artist_fee) ) ) {
        acc += Number(item.ujamaa_artist_fee);
      }
      return acc;
    }, 0);

    return formatNumberToReadable(bizRevenue + artistRevenue);
  }

  const getUjamaaArtworkRevenue = (data) => {
    const revenue = data.reduce((acc, item) => {
      if( !isNaN( Number(item.ujamaa_artist_fee) ) ) {
        acc += Number(item.ujamaa_artist_fee);
      }
      return acc;
    }, 0);
    return formatNumberToReadable(revenue);
  }

  const getUjamaaRepRevenue = (data) => {
    const revenue = data.reduce((acc, item) => {
      if (typeof item.ujamaa_rep_fees === "string") {
        acc += item.ujamaa_rep_fees
          .split(";") // Split into an array of values
          .map(Number) // Convert each to a number
          .filter((num) => !isNaN(num)) // Remove invalid numbers
          .reduce((sum, num) => sum + num, 0); // Sum the valid numbers
      }
      return acc;
    }, 0);
    return formatNumberToReadable(revenue);
  }   
  
  const getBusinessRevenue = (data) => {
    const revenue = data.reduce((acc, item) => {
      if (isRootUser()) {
        // Sum all rep_payouts for root user
        if (typeof item.rep_payout === "string") {
          acc += item.rep_payout
            .split(";")
            .map(Number)
            .filter((num) => !isNaN(num))
            .reduce((sum, num) => sum + num, 0);
        }
      } else {
        // Sum only the rep_payouts matching the userId
        if (typeof item.rep_id === "string" && typeof item.rep_payout === "string") {
          const repIds = item.rep_id.split(";");
          const repPayouts = item.rep_payout.split(";").map(Number);
  
          repIds.forEach((id, index) => {
            if (id === user.id && !isNaN(repPayouts[index])) {
              acc += repPayouts[index];
            }
          });
        }
      }
      return acc;
    }, 0);
  
    return revenue;
  }

  const getBusinessArtistPayouts = (data) => {
    const revenue = data.reduce((acc, item) => {
      if( !isNaN( Number(item.artist_payout) ) ) {
        acc += Number(item.artist_payout);
      }
      return acc;
    }, 0);

    return formatNumberToReadable(revenue);
  }

  const getBusinessPayouts = (data) => {
    const bizPayouts = getBusinessRevenue(data);

    const artistPayouts = data.reduce((acc, item) => {
      if( !isNaN( Number(item.artist_payout) ) ) {
        acc += Number(item.artist_payout);
      }
      return acc;
    }, 0);

    return formatNumberToReadable(Number(bizPayouts) + Number(artistPayouts));
  }

  const getArtistRevenue = (data) => {
    const revenue = data.reduce((acc, item) => {
      if( !isNaN( Number(item.artist_payout) ) ) {
        acc += Number(item.artist_payout);
      }
      return acc;
    }, 0);
    return revenue;
  }

  const getTotalSalesFromOrders = (orders) => {
    const sales = orders.reduce((acc, item) => {
      if( !isNaN( Number(item.order_amount) ) ) {
        acc += Number(item.order_amount);
      }
      return acc;
    }, 0);
    return formatNumberToReadable(sales);
  }

  const getAverageSalesFromOrders = (orders) => {
    const sales = orders.reduce((acc, item) => {
      if( !isNaN( Number(item.order_amount) ) ) {
        acc += Number(item.order_amount);
      }
      return acc;
    }, 0);
    const total = orders.filter( item => !isNaN(Number(item.order_amount)) ).length;
    return formatNumberToReadable(sales/total);
  }

  const getTotalSalesTransactions = (orders) => {
    return orders.filter( item => !isNaN(Number(item.order_amount)) ).length;
  }

  const getNormalizedSalesRevenueData = (orders) => {
    // Get the current date and calculate the last 12 months
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    // Create a map to store aggregated data
    const result = {};

    orders.forEach((order) => {
      // Extract the month-year from the order_date
      const date = new Date(order.order_date);
      const monthYear = `${String(date.getMonth() + 1).padStart(2, "0")}/${String(date.getFullYear()).slice(-2)}`;
  
      // Only process orders within the last 12 months, including the current month
      const orderYear = date.getFullYear();
      const orderMonth = date.getMonth();
      const monthsDifference = (currentYear - orderYear) * 12 + currentMonth - orderMonth;
  
      if (monthsDifference >= 0 && monthsDifference < 12) {
        // Initialize the bucket if it doesn't exist
        if (!result[monthYear]) {
          result[monthYear] = {
            monthYear,
            sales: 0,
            artist_revenue: 0,
            rep_revenue: 0,
            ujamaa_revenue: 0,
          };
        }
  
        // Add the values to the respective bucket
        result[monthYear].sales += Number(order.order_amount) || 0;
        result[monthYear].artist_revenue += Number(order.artist_payout) || 0;
        result[monthYear].rep_revenue += Number(order.dealer_payout) || 0;
        result[monthYear].ujamaa_revenue += Number(order.fees) || 0;
      }
    });

    // Sort the result by month
    const sortedData = Object.values(result).sort((a, b) => {
    const [monthA, yearA] = a.monthYear.split('/');
    const [monthB, yearB] = b.monthYear.split('/');
    const dateA = new Date(`20${yearA}-${monthA}-01`);
    const dateB = new Date(`20${yearB}-${monthB}-01`);
    return dateA - dateB;
  });

  return sortedData.map((entry) => ({
    ...entry,
    sales: Math.round(entry.sales),
    artist_revenue: Math.round(entry.artist_revenue),
    rep_revenue: Math.round(entry.rep_revenue),
    ujamaa_revenue: Math.round(entry.ujamaa_revenue),
    revenue: Math.round(entry.rep_revenue),
  }));
  /*
  
    return Object.values(result).map((entry: any) => ({
      ...entry,
      sales: Math.round(entry.sales),
      artist_revenue: Math.round(entry.artist_revenue),
      rep_revenue: Math.round(entry.rep_revenue),
      ujamaa_revenue: Math.round(entry.ujamaa_revenue),
    }));*/
  }

  const StackedBarChart = ( inventory, orders ) => {
    // Step 1: Normalize data to count inventory and orders per artist
    const artistDataMap = new Map();
  
    // Count inventory per artist
    inventory.forEach((item) => {
      const artist = item.username;
      if (!artistDataMap.has(artist)) {
        artistDataMap.set(artist, { artist_username: artist, inventoryCount: 0, salesCount: 0, inventoryValue: 0, salesValue: 0 });
      }
      artistDataMap.get(artist).inventoryCount += 1; // Count inventory items
      artistDataMap.get(artist).inventoryValue += isRootUser() ? 
                                                  (Number(item.final_price) * Number(item.amount_available)) || 0 : 
                                                  Number(item.total_value ?? item.final_price) || 0;

    });
  
    // Count orders per artist
    orders.forEach((item) => {
      const artist = item.artist_username;
      if (!artistDataMap.has(artist)) {
        artistDataMap.set(artist, { artist_username: artist, inventoryCount: 0, salesCount: 0, inventoryValue: 0, salesValue: 0 });
      }
      artistDataMap.get(artist).salesCount += 1; // Count orders
      artistDataMap.get(artist).salesValue += Number(item.artwork_price);
    });
  
    // Convert Map to array
    const chartData = inventorySalesChartType === INVENTORY_SALES_CHART_TYPE.INVENTORY.label ? 
                        Array.from(artistDataMap.values()).sort((a, b) => (b.inventoryValue - a.inventoryValue)) : 
                        inventorySalesChartType === INVENTORY_SALES_CHART_TYPE.SALES.label ?
                        Array.from(artistDataMap.values()).sort((a, b) => (b.salesValue - a.salesValue)) :
                        Array.from(artistDataMap.values()).sort((a, b) => ((b.salesValue + b.inventoryValue) - (a.salesValue + a.inventoryValue)));    
    return (
      <div style={{ height: "200px", overflowY: "auto", border: "1px solid #ddd", paddingRight: "10px" }}>
    
      <ResponsiveContainer width="100%" height={1200}>
        <BarChart data={chartData} layout="vertical" margin={{ top: 20, right: 10, left: 10, bottom: 5 }}>
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis type="number" tickFormatter={(val) => formatNumberToReadable(val)}/>
          <YAxis dataKey="artist_username" type="category" width={150} />
          <Tooltip formatter={(val) => formatNumberToReadable(val)}/>
          <Legend />
          {inventorySalesChartType === INVENTORY_SALES_CHART_TYPE.BOTH.label || inventorySalesChartType === INVENTORY_SALES_CHART_TYPE.INVENTORY.label ? (
            <Bar dataKey="inventoryValue" stackId="a" fill={COLOR_4} name="Inventory" />
          ) : null}
          {inventorySalesChartType === INVENTORY_SALES_CHART_TYPE.BOTH.label || inventorySalesChartType === INVENTORY_SALES_CHART_TYPE.SALES.label ? (
            <Bar dataKey="salesValue" stackId="a" fill={COLOR_1} name="Sales" />
          ) : null}
        </BarChart>
      </ResponsiveContainer>
      </div>
    );
  };
  
  const generateChart = (type, color) => {
    const normalizedData = getNormalizedSalesRevenueData(data?.orders?.rows);

    return (
      <ResponsiveContainer width="100%" height={30}>
    
          <AreaChart
            data={normalizedData}
            margin={{
              top: 5,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <Area type="monotone" dataKey={type === "sales" ? "sales" : "rep_revenue"} stroke={color} fill={color} />
          </AreaChart>
          </ResponsiveContainer>
  
    )

  }

  const onPageSelectionChanged = (val) => {
    setCurrentPage(val)
  }

  const getPlacardsScannedData = (placardsScanned) => {
    const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const result = {};

  placardsScanned.forEach((scan) => {
    // Convert `created_date` to MM-YY format
    const date = new Date(scan.created_date);
    const monthYear = `${String(date.getMonth() + 1).padStart(2, "0")}/${String(date.getFullYear()).slice(-2)}`;

    // Only process data within the last 12 months, including the current month
    const scanYear = date.getFullYear();
    const scanMonth = date.getMonth();
    const monthsDifference = (currentYear - scanYear) * 12 + currentMonth - scanMonth;

    if (monthsDifference >= 0 && monthsDifference < 12) {
      // Initialize the bucket if not exists
      if (!result[monthYear]) {
        result[monthYear] = { monthYear, scans: 0 };
      }

      // Increment the scan count
      result[monthYear].scans += 1;
    }
  });

  // Convert object to array and sort by date
  return Object.values(result)
    .sort((a, b) => {
      const [monthA, yearA] = a.monthYear.split("/").map(Number);
      const [monthB, yearB] = b.monthYear.split("/").map(Number);
      return yearA === yearB ? monthA - monthB : yearA - yearB;
    });
  }

  const getPlacards = (placardsData) => {
    const uniquePlacards = new Set(placardsData.map(item => item.placard_code));
    return uniquePlacards.size;
  }

  const getOrdersPage = (orders) => {
    return (
      <Card title="Orders" extra={`${orders.length} Found`}>
        <Table
          size='small'
          className={styles.customTable}
          rowKey="id"
          columns={ordersColumns}
          dataSource={orders ? orders.slice().sort((a, b) => b.id - a.id) : []}
          scroll={{
            y: 1200,
          }}
        />
      </Card>
    )
  }

  const getArtistsPage = (data) => {
    const filteredArtists = isRootUser() ? 
                            data?.users?.rows?.slice().filter(artist => artist.is_artist === "1")?.sort((a, b) => b.display_name - a.display_name) || [] :
                            data?.artists?.rows;
    return (
      <Card title="Artists" extra={`${filteredArtists.length} Found`}>
        <Table
          size='small'
          className={styles.customTable}
          rowKey="id"
          columns={isRootUser() ? allArtistsColumns : repArtistsColumns}
          dataSource={filteredArtists}
          scroll={{
            y: 1200,
          }}
        />
      </Card>
    )
  }

  const showPlacardsDetailed = () => {
    setShowPlacardsDrawer(true);
  }

 const togglePlacardsBy = () => {
  setPlacardsBy((prev) =>
    prev === PLACARDS_BY.ARTIST ? PLACARDS_BY.ARTWORK : PLACARDS_BY.ARTIST
  );
}

const getPlacardArtworkCount = (data) => {
  const result = data.reduce((acc, item) => {
    const key = `${item.placard_code}-${item.title}`;
    if (!acc[key]) {
      acc[key] = { placard_code: item.placard_code, title: item.title, count: 0 };
    }
    acc[key].count++;
    return acc;
  }, {});

  return Object.values(result).sort((a, b) => b.count - a.count); // Sort by count descending
};

const getDisplayNameCount = (data) => {
  const result = data.reduce((acc, item) => {
    if (!acc[item.display_name]) {
      acc[item.display_name] = { display_name: item.display_name, count: 0 };
    }
    acc[item.display_name].count++;
    return acc;
  }, {});

  return Object.values(result).sort((a, b) => b.count - a.count); // Sort by count descending
};

const getPlacardsDetailsTable = () => {
  if( placardsBy === PLACARDS_BY.ARTWORK ) {
    const placardArtworkCount = Object.values(getPlacardArtworkCount(filteredDataByDateRange)) || [];

    return (
      <Table
        key="artworkTable"
        size='small'
        className={styles.customTable}
        rowKey={(record, index) => index} // Use the array index as the row key
        columns={placardsByArtworkColumns}
        dataSource={placardArtworkCount}
        scroll={{
          y: 1200,
        }}
      />
    );
  } else {
    const displayNameCount = Object.values(getDisplayNameCount(filteredDataByDateRange));
    return (
      <Table
        key="artistTable"
        size='small'
        className={styles.customTable}
        rowKey={(record, index) => index} // Use the array index as the row key
        columns={placardsByArtistColumns}
        dataSource={displayNameCount}
        scroll={{
          y: 1200,
        }}
      />
    );
  }
}

const handleDateRangeSegmentChange = (value) => {
  setSelectedRange(value);

  if (value === DATE_RANGE.CUSTOM.label) {
    setCustomDates(null); // Reset previous selection
  } else {
    // Apply hook to filter data
    //const filteredData = useFilterByDateRange(data, value);
    //onFilteredData(filteredData);
  }
}

const handleDateChange = (dates) => {
  setCustomDates(dates);
}

const getOverviewPage = () => {
  console.log("In overview...", data, isRootUser())
  return (
    <>
    <Row gutter={[8, 8]} justify="center" >
    <Col xs={24} sm={12} md={8}  className='mb-2'>
    { isRootUser() ? (
      <KpiCard 
        labels={["USERS", "Artists", "Collectors", "Reps"]}
        values={[data.users.rows.length, data.users.rows.filter( item => item.type === 1).length, data.users.rows.filter( item => item.type === 2).length, data.users.rows.filter( item => item.type === 4).length]}
        icon={<UserOutlined style={{fontSize: "32px", color: COLOR_2}}/> } /* "#f97316" */
      />
    ) : (
      <KpiCard 
        labels={["ARTISTS"]}
        values={[data?.artists?.rows?.length]}
        icon={<UserOutlined style={{fontSize: "32px", color: COLOR_2}}/> } /* "#f97316" */
      />
    )}
    </Col>
      
    <Col xs={24} sm={12} md={8}  className='mb-2'>
      <KpiCard 
        labels={["INVENTORY", "Originals", "Prints"]} 
        values={[getInventoryTotal(data), getInventoryOriginals(data) || '0', getInventoryPrints(data) || '0' ]} 
        secondaryValues={[getInventoryTotalValue(data), getInventoryOriginalsValue(data), getInventoryPrintsValue(data)]}
        icon={<ShopOutlined style={{fontSize: "32px", color: COLOR_4}}/> } 
      />
    </Col>

    <Col xs={24} sm={12} md={8}  className='mb-2'>
      <KpiCard
        labels={["PLACARDS", "Scanned" ]}
        values={[getPlacards(data?.placardsScanned?.rows), `${data?.placardsScanned?.rows?.length || 0} times`]}
        icon={<QrcodeOutlined style={{fontSize: "32px", color: COLOR_3}}/> } 
      />
    </Col>
    </Row>

    { isRootUser() ? (
      <Row gutter={4} >
      <Col xs={24} sm={12} md={8} className='mb-2'>
        <KpiCard
          labels={["Gross Sales", "Orders", "Average Sale"]}
          values={[getTotalSalesFromOrders(data?.orders?.rows), getTotalSalesTransactions(data?.orders?.rows), getAverageSalesFromOrders(data?.orders?.rows)]} 
          chart={generateChart("sales", COLOR_2)}
          //height="80px"
        />
      </Col>
      
      <Col xs={24} sm={12} md={8} className='mb-2'>
        <KpiCard
          labels={["Revenue", "Businesses", "Artists"]}
          values={[formatNumberToReadable((getBusinessRevenue(data?.orders?.rows) + getArtistRevenue(data?.orders?.rows)) || 0), formatNumberToReadable(getBusinessRevenue(data?.orders?.rows) || 0), formatNumberToReadable(getArtistRevenue(data?.orders?.rows) || 0)]}
          chart={generateChart("revenue", COLOR_1)}
        />
      </Col>

      <Col xs={24} sm={12} md={8} className='mb-2'>
        <KpiCard
          labels={["Ujamaa Revenue", "Artwork Fee", "Rep Fee"]}
          values={[ getUjamaaRevenue(data?.revenue?.rows) || 0, 
                    getUjamaaArtworkRevenue(data?.revenue?.rows) || 0,
                    getUjamaaRepRevenue(data?.revenue?.rows) || 0]}
          chart={generateChart("placards", COLOR_3)}
        />
      </Col>
      </Row>

      ) : (
        <Row gutter={4} >
      <Col xs={24} sm={12} md={12} className='mb-2'>
        <KpiCard
          labels={["Gross Sales", "Orders", "Average Sale"]}
          values={[getTotalSalesFromOrders(data?.orders?.rows), getTotalSalesTransactions(data?.orders?.rows), getAverageSalesFromOrders(data?.orders?.rows)]} 
          chart={generateChart("sales", COLOR_2)}
          //height="80px"
        />
      </Col>
      
      <Col xs={24} sm={12} md={12} className='mb-2'>
        <KpiCard
          labels={["Payouts", "My Business", "My Artists"]}
          values={[getBusinessPayouts(data?.orders?.rows) || 0, formatNumberToReadable(getBusinessRevenue(data?.orders?.rows) || 0), getBusinessArtistPayouts(data?.orders?.rows) || 0]}
          chart={generateChart("revenue", COLOR_1)}
        />
      </Col>

      {/*<Col xs={24} sm={12} md={8} className='mb-2'>
        <KpiCard
          labels={["Ujamaa Revenue", "Artwork Fee", "Rep Fee"]}
          values={[ getUjamaaRevenue(data?.revenue?.rows) || 0, 
                    getUjamaaArtworkRevenue(data?.revenue?.rows) || 0,
                    getUjamaaRepRevenue(data?.revenue?.rows) || 0]}
          chart={generateChart("placards", COLOR_3)}
        />
      </Col>*/}
      </Row>
        
      )
    }

    <Row gutter={4}>
    <Col span={24} className='mb-5'>
      <Row gutter={4}>
      <Col xs={24} sm={12} md={8}>
        <Card title="Sales/Revenue" >
          <ComposedChart
            width={350}
            height={200}
            data={getNormalizedSalesRevenueData(data?.orders?.rows)}
            margin={{
              top: 5,
              right: 20,
              bottom: 5,
              left: 10,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="monthYear" interval={"preserveStartEnd"} tickFormatter={(tick) => {
              // Assuming `monthYear` is a Date object or a string in "MM/DD/YY" format
              const date = new Date(tick);
              const options = { month: 'short' }; // Short month format (e.g., 'Apr')
              return date.toLocaleString('en-US', options);
            }} />
            {/*<YAxis tickFormatter={(value) => formatNumberToReadable(value)}/>*/}
            <Tooltip formatter={(value) => formatNumberToReadable(value)} contentStyle={{opacity: 0.9}}/>
            <Legend />
            <Area type="monotone" dataKey="sales" fill={COLOR_2} stroke={COLOR_2} activeDot={{ r: 5 }}/>
            <Line type="monotone" dataKey={isRootUser() ? "rep_revenue" : "revenue"} stroke={COLOR_1} activeDot={{ r: 6 }}/>
            { isRootUser() ? (
              <Line type="monotone" dataKey="artist_revenue" stroke={COLOR_4} activeDot={{ r: 7 }}/>
              ) : null
            }
            { isRootUser() ? (
              <Line type="monotone" dataKey="ujamaa_revenue" stroke={COLOR_3} activeDot={{ r: 8 }}/>
              ) : null
            }
          </ComposedChart>
        </Card>
      </Col>

      <Col xs={24} sm={12} md={8}>
        <Card title="Inventory/Sales" 
              extra={ <Segmented  size={"small"} 
                                  options={Object.values(INVENTORY_SALES_CHART_TYPE).map(({ label }) => label)}
                                  value={inventorySalesChartType}
                                  onChange={(value) => setInventorySalesChartType(value)} />}>
          {StackedBarChart(isRootUser() ? data?.inventory?.rows : [...data?.dealerOriginals?.rows, ...data?.dealerPrints?.rows], data?.orders?.rows)}
        </Card>
      </Col>
          
      <Col xs={24} sm={12} md={8}>
        <Card title="Placards/Scanned" 
          extra={
            <Button 
              shape={"circle"} 
              icon={<MoreOutlined />} 
              style={{border: "none"}} 
              onClick={showPlacardsDetailed}
            />
          }
        >
          <ResponsiveContainer width="100%" height={200}>
            <BarChart data={getPlacardsScannedData(data?.placardsScanned?.rows)}>
              <XAxis dataKey="monthYear" 
              tickFormatter={(tick) => {
                // Assuming `monthYear` is a Date object or a string in "MM/DD/YY" format
                const date = new Date(tick);
                const options = { month: 'short' }; // Short month format (e.g., 'Apr')
                return date.toLocaleString('en-US', options);
              }} />
              
              {/*<YAxis allowDecimals={false} />*/}
              <Tooltip />
              <Bar dataKey="scans" fill={COLOR_3} maxBarSize={50}>
              <LabelList 
                dataKey="scans" 
                //position={"insideTop"} 
                fill={"#f0a000"} 
                fontSize={10} 
                content={({ x, y, value, height, width }) => {
                  const minHeight = 15; // Minimum height threshold for labels inside bars
                  const isTooShort = height < minHeight;
                  return (
                    <text 
                      x={x + width / 2} // Center horizontally within the bar
                      y={isTooShort ? y - 5 : y + height / 2} // Move label above if bar is too short
                      fill={isTooShort ? "black" : "white"} // Change text color based on bar size
                      fontSize={12}
                      textAnchor="middle" // Ensures text is centered
                      dominantBaseline="middle" // Keeps text vertically aligned
                    >
                      {value}
                    </text>
                  );
                }} 
              />
              </Bar>
            </BarChart>
          </ResponsiveContainer>

          <Drawer
            placement={"right"}
            width={500}
            closable={false}
            title={
              <div>
                <Space>
                {/* placardsBy === PLACARDS_BY.ARTWORK ? (
                  <PictureOutlined style={{fontSize: "24px", color: COLOR_4}}/>
                ) : (
                  <UserOutlined style={{fontSize: "24px", color: "darkblue"}}/>
                )*/}
                <span style={{ fontSize: "18px", fontWeight: "bold" }}>Placards Scanned - </span>
                <span style={{ fontSize: "16px", fontWeight: "bolder", color: placardsBy === PLACARDS_BY.ARTWORK ? COLOR_4 : COLOR_2}}>{placardsBy.upperCase}</span>
                </Space>
                {/*}
                <br />
                <div>
                <span style={{ fontSize: "14px", color: placardsBy === PLACARDS_BY.ARTWORK ? "orange" : "darkblue", fontWeight: "bolder" }}>BY {placardsBy.upperCase}</span>
                </div>*/}
                
              </div>
            }
            footer={
              <Button 
                type='primary' 
                onClick={togglePlacardsBy}
                ghost 
                style={{
                  width: "100%", 
                  borderColor: placardsBy === PLACARDS_BY.ARTIST ? COLOR_4 : COLOR_2, 
                  color: placardsBy === PLACARDS_BY.ARTIST ? COLOR_4 : COLOR_2 
                }}
              >
                View by {placardsBy === PLACARDS_BY.ARTIST ? PLACARDS_BY.ARTWORK.default : PLACARDS_BY.ARTIST.default}
              </Button>
            }
            extra={
              <Button 
                icon={<CloseOutlined />} 
                style={{border: "none"}} 
                shape={"circle"} 
                onClick={() => setShowPlacardsDrawer(false)} 
              />
            }

            onClose={() => setShowPlacardsDrawer(false)}
            open={showPlacardsDrawer}
          >
          <div style={{
            width: "100%",
            display: "flex",
            flexDirection: "column", 
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
          >
            <Space direction="vertical">
              <Segmented
                options={Object.values(DATE_RANGE).map(({ label }) => label)}
                value={selectedRange}
                onChange={(handleDateRangeSegmentChange)}
              />
              {selectedRange === DATE_RANGE.CUSTOM.label && (
                <RangePicker value={customDates} onChange={handleDateChange} style={{width: "100%"}} />
              )}
            </Space>
          </div>

          <br/>

          {getPlacardsDetailsTable()}
          </Drawer>
        </Card>
      </Col>
      </Row>

      <Row gutter={4}>
      <div style={{width: "100%", marginTop: "0.5rem"}}> 
        <Segmented 
          size="large"
          block
          options={[
            { label: 'Revenue', value: 'sales', icon: <DollarOutlined /> },
            { label: 'Inventory', value: 'inventory', icon: <ShopOutlined /> },
            { label: 'Placards', value: 'placards', icon: <QrcodeOutlined /> },
          ]}
          value={tableType} // Bind the state to the segmented value
          onChange={handleSegmentChange}
        />
          <Card>
            <Table
              size='small'
              className={styles.customTable}
              columns={ tableType === 'sales' ? isRootUser() ? revenueColumnsRoot : revenueColumnsNonRoot : 
                        tableType === 'inventory' ? isRootUser() ? inventoryColumns : inventoryColumnsNonRoot :
                        isRootUser() ? placardsColumnsRoot : placardsColumnsNonRoot
                      }
              dataSource={tableType === 'sales' ? data?.orders?.rows?.sort((a, b) => b.id - a.id) : 
                          tableType === 'inventory' ? isRootUser() ? 
                                                      data?.inventory?.rows?.sort(((a, b) => 
                                                        new Date(b.created_date).getTime() - new Date(a.created_date).getTime())) :
                                                      [...data?.dealerOriginals?.rows, ...data?.dealerPrints?.rows]?.sort((a, b) => 
                                                        new Date(b.created_date).getTime() - new Date(a.created_date).getTime()) :
                          data?.placardsScanned?.rows?.sort(
                            (a, b) => new Date(b.created_date).getTime() - new Date(a.created_date).getTime())
                        }
              scroll={{
                y: 1200,
              }}
            />
          </Card>
        </div>
        </Row>
        </Col>
      </Row>
      </>
    )
  }

  return (
    data?.users?.rows ? (
    <div> 
      <Row>
      <Col span={2}>
        <div>Welcome, <b>{user.profile_name}</b></div>
      </Col>
            
      <Col span={22} style={{textAlign: "right"}}>
        <Space>
        <Button icon={<ReloadOutlined />} onClick={() => window.location.reload()}/>
        
        <Button icon={<LeftOutlined />} disabled={currentPage === 0} onClick={() => setCurrentPage(currentPage - 1)}/>
        
        <Select
          style={{ width: 200, textAlign: "center",  }}
          //dropdownStyle={{backgroundColor: "lavenderblush",}}
          value={currentPage}
          //aria-readonly
          onChange={onPageSelectionChanged}
          options={PAGES} 
        />
        
        <Button icon={<RightOutlined />} disabled={currentPage === 2} onClick={() => setCurrentPage(currentPage + 1)}/>
        </Space>
      </Col>
      </Row>
      
      <hr></hr>

      { currentPage === 0 ? (
          getOverviewPage()
        ) : currentPage === 1 ? (
          getOrdersPage(data?.orders?.rows)
        ) : currentPage === 2 ? (
          getArtistsPage(data)
        ) : null 
      }

      {/*<Card>
          <div>
          <h3>Select a User:</h3>
          <BusinessSelect users={data?.users?.rows} />
          </div>
      </Card>*/}

      
      </div>
    ) : <Skeleton />
    
  )
}

export default Home;
