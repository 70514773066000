import { Button, Empty, Image, Modal, notification, Popconfirm, Select, Space, Spin, Table, TableColumnsType, Tag, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { makeRequest } from '../../helpers/axios-manipulator';
import { DeleteOutlined, LinkOutlined, LoadingOutlined } from '@ant-design/icons';
import { Artwork } from '../../../data/models/artwork';
const { Text, Link } = Typography;

interface DataType {
    key: React.Key;
    code: string;
    artwork_id: string;
    status: string;
    image_url: string;
    title: string
}

const statusTags = {
    "unlinked": "green",
    "inventory": "geekblue",
    "acquisitions": "volcano",
}

const PlacardsTable = ({ artworks, userId, unlinkPlacard, reRenderPlacards }: { artworks: Artwork[], userId: String, unlinkPlacard: Function, reRenderPlacards: number | null }) => {
    const [placards, setPlacards] = useState<null | DataType[]>(null)
    const [loading, setLoading] = useState<boolean>(true);
    const [assignPlacardModal, setAssignPlacardModal] = useState<boolean>(false);
    const [selectedPlacard, setSelectedPLacard] = useState<DataType | null>()
    const [selectedArtwork, setSelectedArtwork] = useState<Artwork | null>()
    const [api, contextHolder] = notification.useNotification();

    function getArtworksNotInPlacards(artworks, placards) {
        const placardArtworkIds = new Set(placards.map(p => p.artwork_id));
        return artworks.filter(a => !placardArtworkIds.has(a.id));
    }

    const columns: TableColumnsType<DataType> = [
        {
            title: 'Action',
            key: 'operation',
            fixed: 'left',
            width: 100,
            render: (row) => <div>
                {row.artwork_id ?
                    <Popconfirm title="Sure to unlink artwork?" onConfirm={async () => {
                        await unlinkPlacard(row.code)
                        getPlacards()
                    }}>
                        <Button icon={<DeleteOutlined />} />
                    </Popconfirm> :
                    <Button onClick={() => { setSelectedPLacard(row); setAssignPlacardModal(true) }} icon={<LinkOutlined />} />
                }
            </div>,
        },
        {
            title: 'code',
            dataIndex: 'code',
            key: 'code',
            render: (text) => <b>{text}</b>,
            filters: placards?.map(fil => ({
                text: fil.code,
                value: fil.code,
            })),
            filterSearch: true,
            onFilter: (value, record) => record.code.startsWith(value as string),
        },
        Table.EXPAND_COLUMN,
        {
            title: 'Linked artwork',
            dataIndex: 'title',
            key: 'artwork',
            filters: placards?.map(fil => ({
                text: fil.title,
                value: fil.title,
            })),
            filterSearch: true,
            onFilter: (value, record) => record.code.startsWith(value as string),
        },
        {
            title: 'Placard status',
            dataIndex: 'status',
            render: (status: string) => (
                <span>
                    <Tag color={statusTags[status]}>
                        {status.toUpperCase()}
                    </Tag>
                </span>
            ),
            key: 'status',
            filters: [
                {
                    text: 'Unlinked',
                    value: 'unlinked',
                },
                {
                    text: 'Linked to inventory',
                    value: 'inventory',
                },
                {
                    text: 'Linked acquired artworks',
                    value: 'acquisitions',
                },
            ],
            onFilter: (value, record) => record.status.indexOf(value as string) === 0,
        },
    ];

    const assignToPlacard = async () => {
        try {
            const responseOld = await makeRequest({
                origin: window.location.origin,
                method: 'put',
                headers: {
                    'Content-Type': 'application/json',
                },
                url: `/api-update-placard`,
                data: { placard: { code: selectedPlacard?.code, is_enabled: true, artwork_id: selectedArtwork?.id } },
            });
            api.success({
                message: 'Success!',
                description:
                    `Placard ${selectedPlacard?.code} updated with success!`,
            });
            setSelectedArtwork(null)
            setSelectedPLacard(null)
            setAssignPlacardModal(false);
            getPlacards();

        } catch (error) {
            api.error({
                message: 'Error!',
                description:
                    'something went wrong during the update',
            });
        }
    }

    const getPlacards = async () => {
        try {
            const response = await makeRequest({
                origin: window.location.origin,
                method: 'get',
                url: `/api-placards-artworks/${userId}`,
                headers: {},
            });
            setPlacards(response)
            setLoading(false)
        } catch (error) {
            console.error('Error fetching business events:', error);
        }
    };

    useEffect(() => {
        getPlacards();
    }, [reRenderPlacards])

    return (
        <>
            {contextHolder}
            {placards && placards.length > 0 ?
                <Table<DataType> scroll={{ x: true }} columns={columns} dataSource={placards}
                    expandable={{
                        expandedRowRender: (record) => <Space>
                            <Image width={100} src={record.image_url}></Image>
                            <div className='flex flex-col'>
                                <Text><b>Title:</b> {record.title}</Text>
                                <Text><b>Id:</b> {record.artwork_id}</Text>
                            </div>
                        </Space>,
                        rowExpandable: (record) => record.artwork_id !== null,
                    }} />
                :
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: "2rem" }}>
                    {loading ?
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} /> :
                        <Empty style={{ marginTop: '3rem' }} />
                    }
                </div>
            }
            {assignPlacardModal &&
                <Modal
                    centered
                    title="Assign artwork"
                    open={assignPlacardModal}
                    okText="Save"
                    onOk={assignToPlacard}
                    onCancel={() => { setAssignPlacardModal(false) }}
                    width={"90vw"}
                    style={{ maxWidth: 500 }}
                >
                    Select the artwork you want to link to placard <Text strong>{selectedPlacard?.code}</Text>
                    <Select
                        showSearch
                        placeholder="Select artwork"
                        style={{ width: '100%' }}
                        optionFilterProp="value"
                        onChange={(e) => setSelectedArtwork(artworks.find((item) => item.id === e))}
                        options={getArtworksNotInPlacards(artworks, placards).map((item) => ({ label: item.title, value: item.id, image: item.image_url }))}
                        optionRender={(option) => (
                            <Space>
                                <img width={60} src={option.data.image} loading="eager" />
                                <Text>{option.data.label}</Text>
                            </Space>
                        )}
                    />
                </Modal>
            }
        </>
    )
}

export default PlacardsTable